/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */

// We'll ride the spiral to the end and may just go where no one's been
// Spiral out, keep going ...

export default p => {
  const phi = 1.6180339
  p.state = []
  p.dispatch = () => {
  }
  let color = [255, 255, 255]
  let x = p.windowWidth / 2;
  let y = p.windowHeight / 2;

  p.windowResized = () => {
    p.resizeCanvas(p.windowWidth - 15, p.windowHeight)
  }

  p.setup = () => {
    p.createCanvas(p.windowWidth - 15, p.windowHeight)
    p.noFill()
    p.smooth()
    p.frameRate(24);
  }

  p.draw = () => {
    p.background("#000000")

    color[p.random([0, 1, 2])]++
    if (color[0] > 240 && color[1] > 240 && color[2] > 240) {
      color = [255, 255, 255]
    }
    p.stroke(color[0], color[1], color[2])
    p.spiral2(p.width / 2, p.height / 2, 0, p.windowWidth)
  }

  p.spiral = (x, y, origin, lines) => {
    for (let i = 0; i < lines; i += 1) {
      p.translate(x, y)
      p.rotate(p.radians(p.noise((p.frameCount - i) * 0.0001 * phi) * 100 + 100))
      p.line(origin, 0, 0, 0)
      p.translate(-x, -y)
      x += origin += phi
    }
  }

  p.spiral2 = (x, y, origin, lines) => {
    for (let i = 0; i < lines; i += 1) {
      p.translate(x, y)
      // p.rotate(p.radians(p.noise((p.frameCount - i) * 0.0001 * phi) * 100 + 100))
      p.rotate(1000 / (p.noise(i) + 1))
      p.line(origin, 10, 10, 10)
      p.fill(255)
      p.translate(-x, -y)
      x += origin += phi
      // if (x > lines) {
      //   x = 0
      // }
      // x += 10
    }
  }
}
