import React from "react"
import Layout from "../components/layout";
import Sketch from "../components/sketch"
import sketch from "../scripts/phi"
import {graphql} from "gatsby";
import SEO from "../components/seo";

export default function NftPost({data}) {
  const post = data.markdownRemark
  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        // description={post.frontmatter.description || post.excerpt || 'nothin’'}
        // image={post.frontmatter.image.childImageSharp.sizes.src}
        pathname={post.fields.slug}
        article
      />
      <div>
        <Sketch sketch={sketch}/>
        <div style={{
          display: `inline`,
          color: `white`,
          fontFamily: `Ubuntu`
        }} dangerouslySetInnerHTML={{__html: post.html}}/>
      </div>
    </Layout>
  )
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
            }
            fields {
                slug
            }
        }
    }
`